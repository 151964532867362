<template>
  <div class="install">
    <div class="installTitle">
      设置
      <img src="../assets/back.png" @click="goBack()" alt="" />
    </div>
    <div class="installList">
      <div class="installItem" @click="goNext(0)">
        <img src="../assets/gonggao.png" alt="" class="left" />
        <div>公告</div>
        <img src="../assets/next.png" alt="" class="right" />
      </div>
      <div class="installItem" @click="goNext(1)">
        <img src="../assets/bangzhu.png" alt="" class="left" />
        <div>帮助</div>
        <img src="../assets/next.png" alt="" class="right" />
      </div>
      <div class="installItem" @click="goNext(2)">
        <img src="../assets/nft.png" alt="" class="left" />
        <div>关于NFT</div>
        <img src="../assets/next.png" alt="" class="right" />
      </div>
      <div class="installItem" @click="goNext(3)">
        <img src="../assets/xitong.png" alt="" class="left" />
        <div>关于我们</div>
        <img src="../assets/next.png" alt="" class="right" />
      </div>
    </div>
    <div class="tcdl" @click="goLoginOut()">退出登录</div>
  </div>
</template>
<script>
export default {
  setup() {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goLoginOut() {
      localStorage.removeItem("app_token");
      this.$router.replace("/index");
    },
    goNext(index) {
      switch (index) {
        case 0:
          this.$router.push("/notice");
          break;
        case 1:
          this.$router.push("/help");
          break;
        case 2:
          this.$router.push("/aboutNFT");
          break;
        case 3:
          this.$router.push("/aboutWm");
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.install {
  .installTitle {
    height: 40px;
    background: #ffffff;
    position: relative;
    font-size: 15px;
    color: #333333;
    line-height: 40px;
    text-align: center;
    font-weight: bold;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 18px;
      width: 16px;
      margin: auto;
    }
  }
  .installList {
    padding: 15px;
    .installItem {
      height: 42px;
      padding-left: 10px;
      padding-right: 17px;
      background: #ffffff;
      border-radius: 5px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .left {
        width: 20px;
      }
      div {
        flex: 1;
        margin-left: 9px;
        font-size: 14px;
        color: #333333;
      }
      .right {
        width: 6px;
      }
    }
  }
  .tcdl {
    width: 345px;
    height: 45px;
    background: linear-gradient(30deg, #8ddaf3 0%, #c1d2ff 100%);
    border-radius: 25px;
    font-size: 15px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    line-height: 45px;
    margin: 0 auto;
  }
}
</style>